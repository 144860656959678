import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PurpleShape from "../assets/images/purpleShape.svg";
import WalletTick from "../assets/images/empty-wallet-tick.svg";
import Loading from "./Loading";
import { BASE_URL } from "../utils";
import axios from "axios";
import * as EmailValidator from "email-validator";

function MintSuccess({
  show,
  onHide,
  handleCloseSuccess,
  contract,
  token,
}: any) {
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const registerEmail = async () => {
    if (!EmailValidator.validate(email)) {
      setError("Invalid e-mail address.");
    } else {
      setLoading(true);
      setSent(true);

      let res = await axios({
        url: `${BASE_URL}registerEmail.php?cache=${Date.now()}`,
        method: "POST",
        data: {
          email,
          contract,
          token,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200) {
        if (res.data.status === true) {
          setLoading(false);
          setSent(true);
        } else {
          setLoading(false);
          setSent(false);
        }
      } else {
        setLoading(false);
        setSent(false);
      }
    }
  };

  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Body className="text-center">
        <div className="d-flex justify-content-center">
          <div className="modal-icon">
            <img src={PurpleShape} alt="Success" className="img-fluid" />
            <img src={WalletTick} alt="Success" className="modal-icon-front" />
          </div>
        </div>
        <h4 className="h4 mb-md-3 mb-1">Mint Successful!</h4>

        {!sent ? (
          <div className="modal-box">
            <p>
              Keep supporting your favourite artists and stay up to date with
              their activity on gram180. Join our newsletter to stay up to date.
            </p>
            <input
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError("");
              }}
              placeholder={"Your e-mail"}
            />
            {error !== "" && (
              <div className="error-text">
                <small>{error}</small>
              </div>
            )}

            <button className="btn-disconnect" onClick={() => registerEmail()}>
              Submit
            </button>
          </div>
        ) : (
          <div className="modal-box">
            <p>
              Keep supporting your favourite artists and stay up to date with
              their activity on gram180. Join our newsletter to stay up to date.
            </p>
            {loading && <Loading width={80} height={80} />}
            {!loading && (
              <p style={{ marginBottom: 0 }}>
                <strong>
                  Congratulations! Check your email to make sure the message
                  from gram180 has arrived.
                </strong>
              </p>
            )}
          </div>
        )}

        <Button variant="solid" onClick={handleCloseSuccess}>
          BACK TO GRAM
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export default MintSuccess;
