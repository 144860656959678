import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Artists from "../assets/images/home-left.png";
import Industry from "../assets/images/home-right.png";
import WaveTop from "../assets/images/waveFilled_01.svg";
import WaveBottom from "../assets/images/waveFilled_02.svg";
import Carousel from "../components/Carousel";
import Shape01 from "../assets/images/polygonShape_01.svg";
import Shape02 from "../assets/images/polygonShape_02.svg";
import FeaturedImage from "../assets/images/featureImg.png";
import HWIcon from "../assets/images/HIW_IconDefault.svg";
import Aos from "aos";

import {
  ApplicationState,
  loadFeaturedArtist,
  loadTracksLatest,
} from "../store";
import { useSelector, useDispatch } from "react-redux";

function HomePage() {
  const dispatch = useDispatch();

  const { tracksLatest } = useSelector(
    (state: ApplicationState) => state.tracksLatestReducer
  );

  const { featuredArtist } = useSelector(
    (state: ApplicationState) => state.featuredArtistReducer
  );

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    await dispatch(loadTracksLatest());
    await dispatch(loadFeaturedArtist());
  };

  useEffect(() => {
    Aos.init({
      duration: 1000,
      offset: 300,
    });
  }, []);

  return (
    <main className="page-content">
      <div className="pt-40 text-center pb-80">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={10} xl={8} data-aos="zoom-in">
              <h1>Music NFTs made easy and for everyone</h1>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="col-grid">
        <Container>
          <Row className="justify-content-lg-between justify-content-center">
            <Col xs={12} lg={5} className="mb-lg-0 mb-5">
              <div className="col-grid-image col-grid-left mb-40">
                <img
                  src={Artists}
                  alt="Artists"
                  data-aos="fade-right"
                  data-aos-delay="200"
                />
                <h2 className="mb-0">
                  <span>
                    Authentic Music Collectibles: NFTs Simplified for Fans &
                    Artists
                  </span>
                </h2>
              </div>
              <p className="lead">
                Explore our NFT platform, offering exclusive collectibles from
                renowned artists. Own certified, limited-edition NFTs and
                support creators, all with a simple click.
              </p>
              <Link to="/how-it-works" className="btn btn-lg btn-solid">
                How it works
              </Link>
            </Col>
            <Col xs={12} lg={5}>
              <div className="col-grid-image col-grid-right mb-40">
                <img
                  src={Industry}
                  alt="Industry"
                  data-aos="fade-left"
                  data-aos-delay="200"
                />
                <h2 className="mb-40">
                  <span>For the music industry from the music industry</span>
                </h2>
              </div>
              <p className="lead">
                Helping artists bringing their records to the digital era. No
                technical knowledge needed, direct integration to distribution
                platforms.
              </p>
              <Link to="/about-us" className="btn btn-lg btn-solid">
                About us
              </Link>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="divider overflow-hidden mb-3">
        <img src={WaveTop} alt="WaveTop" className="img-fluid" />
      </div>

      <div className="overflow-hidden" data-aos="fade-up">
        <Container className="pb-60 position-relative">
          <img src={Shape01} alt="Polygon shape" className="shape-01" />
          <div className="section-subheading mb-2">
            <h6 className="text-uppercase mb-0">Latest Releases</h6>
          </div>

          <div className="mb-0">
            <Carousel tracks={tracksLatest} />
          </div>
          <div className="text-center">
            <Link to="/latest" className="btn btn-lg btn-solid">
              discover tracks
            </Link>
          </div>
        </Container>
      </div>

      {featuredArtist.length > 0 && (
        <div className="overflow-hidden">
          <Container
            fluid="md"
            className="pt-100 position-relative featured-artist"
          >
            <img src={Shape02} alt="Polygon shape" className="shape-02" />
            <div className="section-subheading">
              <h6 className="text-uppercase mb-0">featured artist</h6>
            </div>

            {featuredArtist.map((item: any) => (
              <Row
                className="align-items-center gx-lg-5"
                key={JSON.stringify(item)}
              >
                <Col md={5} className="mb-md-0 mb-4" data-aos="fade-right">
                  <a href={item.link_url}>
                    <img
                      src={item.image_url}
                      alt={item.name}
                      className="img-fluid featured-img"
                    />
                  </a>
                </Col>
                <Col md={7} data-aos="fade-left">
                  <div className="section-subheading section-subheading-sm mb-2">
                    <h6 className="text-uppercase text-muted mb-0">
                      <a href={item.link_url}>{item.name}</a>
                    </h6>
                  </div>
                  <h2>
                    <a href={item.link_url}>{item.heading}</a>
                  </h2>
                  <p>{item.description}</p>
                </Col>
              </Row>
            ))}
          </Container>
        </div>
      )}

      <div className="divider overflow-hidden mb-3">
        <img src={WaveBottom} alt="WaveTop" className="img-fluid" />
      </div>

      <div className="how-works pt-lg-0 pt-4 text-center pb-80">
        <Container>
          <h3 className="mb-40">How it works?</h3>

          <Row className="gx-lg-5">
            <Col
              md={12}
              className="mb-md-0 mb-5"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/dQw4w9WgXcQ"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </Col>
            {/* <Col
              md={4}
              className="mb-md-0 mb-5"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="px-lg-4">
                <img src={HWIcon} alt="HWIcon" className="mb-3" />
                <h5>How it works title 1</h5>
                <p>
                  Our mission is to simplify this process as much as possible.
                  We want to give music artists a platform to create NFTs
                  without any new knowledge.
                </p>
              </div>
            </Col>
            <Col
              md={4}
              className="mb-md-0 mb-5"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="px-lg-4">
                <img src={HWIcon} alt="HWIcon" className="mb-3" />
                <h5>How it works title 2</h5>
                <p>
                  We at gram180 think of NFTs as those classic vinyl records.
                  Once you press a vinyl, it’s done. It becomes a physical
                  record that carries the music.
                </p>
              </div>
            </Col>
            <Col md={4} data-aos="fade-up" data-aos-delay="500">
              <div className="px-lg-4">
                <img src={HWIcon} alt="HWIcon" className="mb-3" />
                <h5>How it works title 3</h5>
                <p>
                  Maybe you’ve heard the process is called “minting”, but here
                  at gram180, we call it pressing. Because we say that an NFT
                  record is basically a digital vinyl record.
                </p>
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
    </main>
  );
}

export default HomePage;
