import { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import Search from "../assets/images/search.svg";
import Arrow from "../assets/images/arrow-down.svg";
import Card from "../components/CardItem";
import DropImage from "../assets/images/coverImg.png";
import axios from "axios";
import LoadingImg from "../assets/images/loading-small.svg";

import {
  ApplicationState,
  loadArtists,
  loadTracksLatestFilter,
} from "../store";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL, getImage, getRandomPlaceholder } from "../utils";
import CardLoading from "../components/CardItemLoading";

const placeholder = getRandomPlaceholder();

function LatestPage() {
  const dispatch = useDispatch();
  const searchDropdown = useRef(null) as any;

  const [artistsData, setArtistsData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [openSearch, setOpenSearch] = useState(false);

  const [filterType, setFilterType] = useState(0);

  const { artists } = useSelector(
    (state: ApplicationState) => state.artistsReducer
  );

  const [offset, setOffset] = useState(1);

  const [tracksData, setTracksData] = useState([]) as any[];
  const [showLoadMore, setShowLoadMore] = useState(true);

  const [loading, setLoading] = useState(true);

  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    loadTracks(filterType);
    loadData();
  }, []);

  const loadData = async () => {
    await dispatch(loadArtists());
  };

  useEffect(() => {
    if (searchText) {
      setArtistsData(
        artists.filter((q: any) => {
          return q.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
        })
      );
    } else {
      setArtistsData(artists);
    }
  }, [artists, searchText]);

  const loadTracks = async (type: number) => {
    setLoading(true);
    setOffset(1);
    setTracksData([]);
    try {
      const response = await axios.get<any>(
        `${BASE_URL}getTracksLatestFilter.php?order=${type}&cache=${Date.now()}`
      );

      if (!response) {
        setLoading(false);
      } else {
        setTracksData(response.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const loadMoreTracks = async (type: number, offset: number) => {
    setLoadingMore(true);

    try {
      const response = await axios.get<any>(
        `${BASE_URL}getTracksLatestFilter.php?order=${type}&offset=${offset}&cache=${Date.now()}`
      );

      if (!response) {
        setLoadingMore(false);
      } else {
        setOffset(offset + 1);
        setTracksData([...tracksData, ...response.data]);
        if (response.data.length < 4) {
          setShowLoadMore(false);
        }
        setLoadingMore(false);
      }
    } catch (error) {
      setLoadingMore(false);
    }
  };

  return (
    <main className="page-content bg-shape">
      <div className="pt-60 text-center mb-40">
        <Container>
          <div className="position-relative">
            <Dropdown>
              <Dropdown.Toggle variant="reset" id="dropdown-basic">
                <InputGroup>
                  <Button variant="btn-reset">
                    <img src={Search} alt="Seacrh" />
                  </Button>
                  <Form.Control
                    placeholder="Search for artist"
                    className="border-0"
                    onChange={(e) => {
                      setOpenSearch(false);
                      setSearchText(e.target.value);
                      setTimeout(() => {
                        setOpenSearch(true);
                      }, 10);
                    }}
                    onFocus={() => setOpenSearch(true)}
                    onBlur={() => setOpenSearch(false)}
                  />
                </InputGroup>
              </Dropdown.Toggle>

              <Dropdown.Menu className={openSearch ? "show" : "hidden"}>
                {artistsData.map((artist: any) => {
                  return (
                    <Dropdown.Item
                      href={"artist/" + artist.permalink}
                      className="d-flex align-items-center"
                      key={String(artist.id_original)}
                    >
                      <img
                        src={
                          artist.image === ""
                            ? placeholder
                            : getImage(artist.image)
                        }
                        alt={artist.name}
                        className="mask dropdown-image"
                      />
                      {artist.name}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Container>
      </div>
      <div className="pb-80">
        <Container>
          <Row className="mb-4 align-items-center justify-content-between">
            <Col sm={4} md={4} className="mb-sm-0 mb-4">
              <h4 className="mb-0">Latest tracks</h4>
            </Col>
            <Col sm={6} md={4} className="d-flex align-items-center">
              <h6 className="heading mb-0 me-3">Sort by</h6>
              <Dropdown className="flex-grow-1">
                <Dropdown.Toggle
                  variant="white"
                  className="d-flex align-items-center justify-content-between"
                >
                  <span>
                    {filterType === 0 && "Latest"}
                    {filterType === 1 && "Price: low to high"}
                    {filterType === 2 && "Price: high to low"}
                  </span>
                  <img src={Arrow} alt="Arrow" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setFilterType(0);
                      setOffset(1);
                      setShowLoadMore(true);
                      loadTracks(0);
                    }}
                  >
                    Latest
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setFilterType(1);
                      setOffset(1);
                      setShowLoadMore(true);
                      loadTracks(1);
                    }}
                  >
                    Price: low to high
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setFilterType(2);
                      setOffset(1);
                      setShowLoadMore(true);
                      loadTracks(2);
                    }}
                  >
                    Price: high to low
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            {tracksData.map((track: any) => (
              <Col sm={6} md={4} lg={3} key={String(track.id)}>
                <Card
                  id={track.id}
                  name={track.name}
                  artist={track.artist}
                  image={getImage(track.image)}
                  user_id={track.user_id}
                  contract={track.contract}
                  transaction_data={track.transaction_data}
                  animation_url={track.animation_url}
                  permalink={track.permalink}
                />
              </Col>
            ))}

            {(loading || loadingMore) && (
              <>
                {[...Array(8)].map((elementInArray, index) => (
                  <Col sm={6} md={4} lg={3} key={String(index)}>
                    <CardLoading />
                  </Col>
                ))}
              </>
            )}
          </Row>

          {showLoadMore &&
            tracksData.length >= 8 &&
            tracksData.length % 8 === 0 && (
              <div className="text-center">
                <Button
                  variant="solid"
                  className="btn btn-lg"
                  onClick={() => loadMoreTracks(filterType, offset)}
                >
                  {loadingMore && (
                    <img
                      src={LoadingImg}
                      alt="Loading"
                      className="loading-box"
                    />
                  )}{" "}
                  LOAD MORE
                </Button>
              </div>
            )}
        </Container>
      </div>
    </main>
  );
}

export default LatestPage;
