import { polygonMumbai } from "wagmi/chains";

export const USDC_CONTRACT = "0xFEca406dA9727A25E71e732F9961F680059eF1F9";

export const BASE_URL = "https://api.gram180.com/web/";
export const BASE_URL2 = "https://api.gram180.com/";

export const WEB_URL = "https://dev.gram180.com";
export const IPFS_URL = "https://nftstorage.link/ipfs/";
export const POLYGONSCAN_URL = "https://mumbai.polygonscan.com";
export const OPENSEA_URL = "https://testnets.opensea.io/assets/mumbai/";

export const INFURA_URL =
  "https://polygon-mumbai.infura.io/v3/8bafa3baede244dea13e1b4700922fbc";

export * from "./helpers";

export const enabledChains = [polygonMumbai];
