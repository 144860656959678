import { BASE_URL2, OPENSEA_URL, POLYGONSCAN_URL } from ".";
import Placeholder1 from "../assets/images/placeholder/default_1@2x.png";
import Placeholder2 from "../assets/images/placeholder/default_2@2x.png";
import Placeholder3 from "../assets/images/placeholder/default_3@2x.png";
import Placeholder4 from "../assets/images/placeholder/default_4@2x.png";
import Placeholder5 from "../assets/images/placeholder/default_5@2x.png";

export function getImage(url: string) {
  return `${BASE_URL2}ipfs-cache/image-handler.php?url=${url}&size=f`;
}

export function getRandomPlaceholder() {
  let items = [
    Placeholder1,
    Placeholder2,
    Placeholder3,
    Placeholder4,
    Placeholder5,
  ];
  return items[Math.floor(Math.random() * items.length)];
}

export function getPolygonScan(contract: string) {
  return POLYGONSCAN_URL + "/address/" + contract;
}

export function getOpenSeaLink(contract: string) {
  return OPENSEA_URL + "" + contract;
}

export function getReleaseDate(date: string) {
  var mySQLDate = date + " 00:00:00";
  return new Date(Date.parse(mySQLDate.replace(/-/g, "/"))).toLocaleDateString(
    "en-US"
  );
}

export function getTransactionDate(date: string) {
  var mySQLDate = date;

  let formattedStr = mySQLDate.replace(" ", "T") + "Z";

  let utcDate = new Date(formattedStr);

  return utcDate.toLocaleString();
}

export const getMintToken = (data: string) => {
  if (data) {
    let value = JSON.parse(data)["logs"][0]["data"].slice(2);
    let size = value.length / 2;

    let half1 = value.substring(0, value.length / 2);
    let half2 = value.substring(value.length / 2);

    return parseInt(half1, 16);
  } else {
    return 0;
  }
};

export const getProgress = (max: number, value: number) => {
  return (100 / max) * value;
};
