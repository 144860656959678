import React, { useEffect, useState, useMemo } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  ProgressBar,
  Row,
  Table,
} from "react-bootstrap";
import Card from "../components/CardItem";
import truncateEthAddress from "truncate-eth-address";

import LoadingImg from "../assets/images/loading-small.svg";
import { Link } from "react-router-dom";

import { useParams } from "react-router-dom";

import { ApplicationState, onLoading, loadArtist, loadDetail } from "../store";
import { useSelector, useDispatch } from "react-redux";
import {
  BASE_URL,
  getImage,
  getMintToken,
  getOpenSeaLink,
  getPolygonScan,
  getProgress,
  getReleaseDate,
  getTransactionDate,
  POLYGONSCAN_URL,
} from "../utils";
import MaxSupply from "../components/chain/MaxSupply";
import Price from "../components/chain/Price";
import SoldDetail from "../components/chain/SoldDetail";

import axios from "axios";
import PlayerDetail from "../components/chain/PlayerDetail";
import Skeleton from "react-loading-skeleton";
import CardLoading from "../components/CardItemLoading";
import Error from "../components/Error";
import { useDisconnect } from "wagmi";

function DetailPage() {
  const [showText, setShowText] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const { disconnect } = useDisconnect();

  const [totalSupply, setTotalSupply] = useState(null) as any;
  const [maxSupply, setMaxSupply] = useState(null) as any;

  const [price, setPrice] = useState(null) as any;
  const [activeId, setActiveId] = useState("");

  const dispatch = useDispatch();
  let { id } = useParams();
  let { permalink } = useParams();

  const [loaded, setLoaded] = useState(false);
  const [offset, setOffset] = useState(1);

  const [tracksData, setTracksData] = useState([]) as any[];
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const [loading, setLoading] = useState(true);

  const [salesData, setSalesData] = useState([]) as any[];

  const [notFound, setNotFound] = useState(false);

  const [detail, setDetail] = useState({
    id: "",
    name: "",
    image: "",
    description: "",
    tokens: "",
    suggested_price: "",
    animation_url: "",
    release_date: "",
    genre: "",
    transaction: "",
    transaction_data: "",
    metadata: "",
    status: "",
    approved: "",
    updated: "",
    user_id: "",
    artist: "",
    contract: "",
    mint_token: "",
    success: "",
  }) as any;

  let maxLength = 100;

  useEffect(() => {
    if (id && permalink) {
      setActiveId(id);
      loadData(id, permalink);

      loadData(id, permalink);
      setLoaded(true);
      disconnect();
    }
  }, [id, permalink]);

  const loadData = async (id: string, permalink: string) => {
    setLoading(true);
    try {
      const response = await axios.get<any>(
        `${BASE_URL}getDetail.php?id=${id}&permalink=${permalink}&cache=${Date.now()}`
      );

      if (!response) {
        setLoading(false);
        setNotFound(true);
      } else {
        if (response.data.success === "false") {
          setNotFound(true);
        } else {
          setLoading(false);
          let newData = { ...response.data };
          newData.image = getImage(newData.image);
          newData.release_date = getReleaseDate(newData.release_date);
          newData.mint_token = getMintToken(newData.transaction_data);
          setDetail(newData);
          loadTracks(permalink, id);
          loadSales(newData.contract, getMintToken(newData.transaction_data));
        }
      }
    } catch (error) {
      setLoading(false);
      setNotFound(true);
    }
  };

  const loadTracks = async (permalink: string, activeId: string) => {
    try {
      const response = await axios.get<any>(
        `${BASE_URL}getTracksByPermalink.php?permalink=${permalink}&active_id=${activeId}&cache=${Date.now()}`
      );

      if (!response) {
      } else {
        setTracksData(response.data);
      }
    } catch (error) {}
  };

  const loadMoreTracks = async (offset: number, activeId: string) => {
    setLoadingMore(true);

    try {
      const response = await axios.get<any>(
        `${BASE_URL}getTracksByPermalink.php?permalink=${permalink}&active_id=${activeId}&offset=${offset}&cache=${Date.now()}`
      );

      if (!response) {
        setLoadingMore(false);
      } else {
        setOffset(offset + 1);
        setTracksData([...tracksData, ...response.data]);
        if (response.data.length < 4) {
          setShowLoadMore(false);
        }
        setLoadingMore(false);
      }
    } catch (error) {
      setLoadingMore(false);
    }
  };

  const loadSales = async (contract: string, token: number) => {
    try {
      const response = await axios.get<any>(
        `${BASE_URL}getSales.php?contract=${contract}&token=${token}&cache=${Date.now()}`
      );

      if (!response) {
      } else {
        setSalesData(response.data);
      }
    } catch (error) {}
  };

  const shortText = (text: string) => {
    if (typeof text === "undefined") {
      return "";
    }
    if (text.length > maxLength) {
      let trimmedString = text.substr(0, maxLength);
      trimmedString = trimmedString.substr(
        0,
        Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
      );
      return trimmedString + "...";
    } else {
      return text;
    }
  };

  if (notFound) {
    return <Error number={404} />;
  }

  return (
    <main className="page-content">
      <div className="pt-40 pb-80 bg-shape">
        <Container>
          <Row className="mb-80 details gx-lg-5">
            <Col md={4} lg={6} className="mb-md-0 mb-5">
              <div className="audio-card">
                {loading ? (
                  <Skeleton
                    height={616}
                    style={{ borderRadius: 12 }}
                    baseColor={"#F1F4F8"}
                    highlightColor={"#E5E8EB"}
                  />
                ) : (
                  <PlayerDetail
                    audioUrl={detail.animation_url}
                    name={detail.name}
                    image={detail.image}
                    id={detail.id}
                  />
                )}
              </div>
            </Col>
            <Col md={8} lg={6}>
              {loading ? (
                <h6>
                  <Skeleton
                    width={100}
                    baseColor={"#F1F4F8"}
                    highlightColor={"#E5E8EB"}
                  />
                </h6>
              ) : (
                <h6>
                  <Link to={"/artist/" + detail.permalink}>
                    {detail.artist}
                  </Link>
                </h6>
              )}

              <h3 className="mb-40">
                {loading ? (
                  <Skeleton
                    width={320}
                    baseColor={"#F1F4F8"}
                    highlightColor={"#E5E8EB"}
                  />
                ) : (
                  detail.name
                )}
              </h3>

              {loading ? (
                <h5 className="mb-24">
                  <Skeleton
                    width={120}
                    baseColor={"#F1F4F8"}
                    highlightColor={"#E5E8EB"}
                  />
                </h5>
              ) : (
                <>
                  {detail.description !== "" && (
                    <h5 className="mb-24">TRACK INFO</h5>
                  )}
                </>
              )}

              {typeof detail.description !== "undefined" && (
                <>
                  {loading ? (
                    <Skeleton
                      width={"100%"}
                      count={3}
                      baseColor={"#F1F4F8"}
                      highlightColor={"#E5E8EB"}
                    />
                  ) : (
                    <>
                      {detail.description !== "" && (
                        <>
                          {!showText ? (
                            <div className="desc mb-24">
                              {shortText(detail.description)}
                            </div>
                          ) : (
                            <div className="desc mb-24">
                              {detail.description}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {detail.description.length > maxLength && (
                    <h6 className="mb-24 text-end">
                      <Button
                        onClick={() => setShowText(!showText)}
                        className="btn-reset"
                      >
                        {!showText ? "SHOW MORE" : "SHOW LESS"}
                      </Button>
                    </h6>
                  )}
                </>
              )}

              {loading ? (
                <h5 className="mb-24">
                  <Skeleton
                    width={120}
                    baseColor={"#F1F4F8"}
                    highlightColor={"#E5E8EB"}
                  />
                </h5>
              ) : (
                <h5 className="mb-24">TRACK PROPERTIES</h5>
              )}

              {loading ? (
                <Row className="mb-1 gx-3">
                  <Col sm={4} xs={6} className="mb-3">
                    <Skeleton
                      width={"100%"}
                      baseColor={"#F1F4F8"}
                      highlightColor={"#E5E8EB"}
                      height={68}
                    />
                  </Col>
                  <Col sm={4} xs={6} className="mb-3">
                    <Skeleton
                      width={"100%"}
                      baseColor={"#F1F4F8"}
                      highlightColor={"#E5E8EB"}
                      height={68}
                    />
                  </Col>
                </Row>
              ) : (
                <Row className="mb-1 gx-3">
                  {JSON.parse(detail.genres).length > 0 && (
                    <>
                      {JSON.parse(detail.genres).map(
                        (genre: string, index: number) => (
                          <Col
                            sm={4}
                            xs={6}
                            className="mb-3"
                            key={JSON.stringify(genre + "_" + index)}
                          >
                            <div className="property">
                              <h6>GENRE</h6>
                              {genre}
                            </div>
                          </Col>
                        )
                      )}
                    </>
                  )}

                  {detail.release_date !== "" && (
                    <Col sm={4} xs={6} className="mb-3">
                      <div className="property">
                        <>
                          <h6>RELEASE DATE</h6>
                          {detail.release_date}
                        </>
                      </div>
                    </Col>
                  )}
                </Row>
              )}

              {/* <h6 className="mb-24 text-end">
                <Button
                  onClick={() => setShowBox(!showBox)}
                  className="btn-reset"
                >
                  {!showBox ? "SHOW MORE" : "SHOW LESS"}
                </Button>
              </h6> */}

              {/* <SoldDetail
                token={getMintToken(detail.transaction_data)}
                contract={detail.contract}
              /> */}
              {detail.transaction_data && detail.contract ? (
                <SoldDetail
                  token={getMintToken(detail.transaction_data)}
                  contract={detail.contract}
                  clientId={detail.crossmint_id}
                />
              ) : (
                <>
                  <h5 className="mb-24" style={{ marginTop: -13 }}>
                    <Skeleton
                      width={120}
                      baseColor={"#F1F4F8"}
                      highlightColor={"#E5E8EB"}
                      height={20}
                    />
                  </h5>
                  <Skeleton
                    width={"100%"}
                    baseColor={"#F1F4F8"}
                    highlightColor={"#E5E8EB"}
                    height={142}
                  />
                </>
              )}
            </Col>
          </Row>

          {salesData.length > 0 && (
            <>
              <div className="section-subheading mb-2">
                <h6 className="text-uppercase mb-0">PURCHASE HISTORY</h6>
              </div>
              <Table className="mb-40" responsive>
                <thead>
                  <tr>
                    <th>Event</th>
                    <th>PRICE PER ITEM</th>
                    <th>FROM</th>
                    <th>TO</th>
                    <th>DATE</th>
                  </tr>
                </thead>
                <tbody>
                  {salesData.map((sale: any) => (
                    <tr key={JSON.stringify(sale)}>
                      <td style={{ textTransform: "capitalize" }}>
                        {sale.type}
                      </td>
                      <td>
                        <strong>${Number(sale.price / 1000000)}</strong>
                      </td>
                      <td>
                        <a
                          href={
                            POLYGONSCAN_URL + "/address/" + sale.account_from
                          }
                          target={"_blank"}
                          className="text-purple"
                          rel="noreferrer"
                        >
                          {sale.account_from ===
                          "0x0000000000000000000000000000000000000000"
                            ? detail.artist
                            : truncateEthAddress(sale.account_from)}
                        </a>
                      </td>
                      <td>
                        <a
                          href={POLYGONSCAN_URL + "/address/" + sale.account_to}
                          target={"_blank"}
                          className="text-purple"
                          rel="noreferrer"
                        >
                          {truncateEthAddress(sale.account_to)}
                        </a>
                      </td>
                      <td>{getTransactionDate(sale.transaction_date)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}

          {tracksData.length > 0 && (
            <>
              <div className="section-subheading mb-2">
                <h6 className="text-uppercase mb-0">MORE FROM ARTIST</h6>
              </div>
              <Row>
                {tracksData.map((track: any) => (
                  <Col sm={6} md={4} lg={3} key={track.id}>
                    <Card
                      id={track.id}
                      name={track.name}
                      artist={track.artist}
                      image={getImage(track.image)}
                      user_id={track.user_id}
                      contract={track.contract}
                      transaction_data={track.transaction_data}
                      animation_url={track.animation_url}
                      permalink={track.permalink}
                    />
                  </Col>
                ))}
              </Row>
            </>
          )}

          {(loading || loadingMore) && (
            <Row>
              {[...Array(8)].map((elementInArray, index) => (
                <Col sm={6} md={4} lg={3} key={String(index)}>
                  <CardLoading />
                </Col>
              ))}
            </Row>
          )}

          {showLoadMore && tracksData.length >= 8 && (
            <div className="text-center">
              <Button
                variant="solid"
                className="btn btn-lg"
                onClick={() => loadMoreTracks(offset, activeId)}
              >
                {loadingMore && (
                  <img src={LoadingImg} alt="Loading" className="loading-box" />
                )}{" "}
                LOAD MORE
              </Button>
            </div>
          )}
        </Container>
      </div>
    </main>
  );
}

export default DetailPage;
