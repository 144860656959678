import axios from "axios";
import { Dispatch } from "react";
import { BASE_URL } from "../../utils";

export interface TracksLatestInterface {
  readonly type: "LOAD_TRACKS_LATEST";
  payload: any;
}

export type LoadTracksLatest = TracksLatestInterface;

export const loadTracksLatest = () => {
  return async (dispatch: Dispatch<TracksLatestInterface>) => {
    try {
      const response = await axios.get<any>(
        `${BASE_URL}getTracksLatest.php?cache=${Date.now()}`
      );

      if (!response) {
      } else {
        dispatch({
          type: "LOAD_TRACKS_LATEST",
          payload: response.data,
        });
      }
    } catch (error) {}
  };
};
