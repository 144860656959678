import React, { useEffect, useRef, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import { Button, ProgressBar } from "react-bootstrap";
import Play from "../../assets/images/playButton.inline.svg";
import Pause from "../../assets/images/pauseButton.inline.svg";
import { IPFS_URL } from "../../utils";

import { useDispatch, useSelector } from "react-redux";
import { ApplicationState, loadPlay } from "../../store";

export default function PlayerDetail({ audioUrl, name, image, id }: any) {
  const audioPlayer = useRef(null) as any;
  const dispatch = useDispatch();

  const { play } = useSelector((state: ApplicationState) => state.playReducer);

  const [playing, setPlaying] = useState(false);

  const [duration, setDuration] = useState("00:00");
  const [durationActive, setDurationActive] = useState("00:00");

  const [durationNO, setDurationNO] = useState(0);
  const [durationNOActive, setDurationNOActive] = useState(0);

  const [durationPercent, setDurationPercent] = useState(0);

  useEffect(() => {
    setDurationPercent((100 / durationNO) * durationNOActive);
  }, [durationNO, durationNOActive]);

  useEffect(() => {
    if (play !== id && play !== "") {
      pause();
      audioPlayer.current.audio.current.currentTime = 0;
    }
  }, [play]);

  const handlePlay = () => {
    setPlay(id);
    audioPlayer.current.audio.current.play();
    setPlaying(true);
  };

  const pause = () => {
    setPlay("");
    audioPlayer.current.audio.current.pause();
    setPlaying(false);
  };

  const setPlay = async (playValue: string) => {
    await dispatch(loadPlay(playValue));
  };

  return (
    <>
      <div className="audio-image">
        <img src={image} alt={name} className="w-100 img-fluid cover" />
        {!playing ? (
          <Button className="btn-reset card-btn" onClick={() => handlePlay()}>
            <img src={Play} alt="Play" />
          </Button>
        ) : (
          <Button className="btn-reset card-btn" onClick={() => pause()}>
            <img src={Pause} alt="Pause" />
          </Button>
        )}
      </div>
      <div className="audio-control d-flex align-items-center">
        <span style={{ width: 37 }}>{durationActive}</span>
        <ProgressBar
          now={durationPercent}
          onClick={(e: any) => {
            let rect = e.currentTarget.getBoundingClientRect();
            let x = e.clientX - rect.left;
            let selectPercent = (100 / rect.width) * x;

            audioPlayer.current.audio.current.currentTime =
              (durationNO / 100) * selectPercent;
          }}
          style={{ cursor: "pointer" }}
        />
        <span>{duration}</span>
      </div>
      {audioUrl && (
        <AudioPlayer
          src={IPFS_URL + "" + audioUrl}
          autoPlay={false}
          ref={audioPlayer}
          onLoadedData={(e: any) => {
            setDurationNO(Number(e.target.duration));
            setDuration(
              new Date(Math.round(e.target.duration) * 1000)
                .toISOString()
                .substring(14, 19)
            );
          }}
          listenInterval={4}
          onListen={(e: any) => {
            setDurationNOActive(Number(e.target.currentTime));
            setDurationActive(
              new Date(Math.round(e.target.currentTime) * 1000)
                .toISOString()
                .substring(14, 19)
            );
          }}
          onEnded={() => {
            setPlaying(false);
            audioPlayer.current.audio.current.currentTime = 0;
            audioPlayer.current.audio.current.pause();
          }}
          style={{ display: "none" }}
        />
      )}
    </>
  );
}
